import { createSlice } from "@reduxjs/toolkit";
import { CONTRACT_ABI, CONTRACT_ADDRESS, TOKEN_CONTRACT_ABI, TOKEN_CONTRACT_ADDRESS } from "../Config/Config";
// Slice
const initialState = {
    contract: null,
    tokenContract: null,
    totalInvested: 0,
    totalUsers: 0,
    userInvestment:0,
    refClaimable:0,
    refClaimed:0,
    userRewards:0,
};
const slice = createSlice({
    name: "ContractInformation",
    initialState,
    reducers: {
        addContractInfomation: (state, action) => {
            state.contract = action.payload.contract;
            state.tokenContract = action.payload.tokenContract;
            state.totalInvested = action.payload.totalInvested;
            state.userInvestment = action.payload.userInvestment;
            state.refClaimable = action.payload.refClaimable;
            state.refClaimed = action.payload.refClaimed;
            state.userRewards = action.payload.userRewards;
            
            
        },
        deleteContractInformation: (state, action) => {
            state.contract = null;
            state.tokenContract = null;
            state.totalInvested = 0;
            state.userInvestment = 0;
            state.refClaimable = 0;
            state.refClaimed = 0;
            state.userRewards = 0;
            
        },
    },
});

export default slice.reducer;

// Actions

const { addContractInfomation, deleteContractInformation } = slice.actions;

export const contractsInfos = (web3, account) => async (dispatch) => {
    try {

        // let nativeBalance = await web3.eth.getBalance(account);
        let contract = await new web3.eth.Contract(
            CONTRACT_ABI,
            CONTRACT_ADDRESS
        );
        let tokenContract = await new web3.eth.Contract(
            TOKEN_CONTRACT_ABI,
            TOKEN_CONTRACT_ADDRESS
        );

        let amount = await contract.methods.getUserTotalDeposit(account).call();
        let userInvestment = amount / 10 ** Number(await tokenContract.methods.decimals().call());

        let userRewards = await contract.methods.calclulateReward(account).call() / 10 ** Number(await tokenContract.methods.decimals().call());        
        let refClaimed = 0;
        let refClaimable = 0;
        if(userRewards>0){
            let userProfits  = await contract.methods.refferralRewards(account).call();            
            refClaimed = userProfits.totalEarn / 10 ** Number(await tokenContract.methods.decimals().call());
            refClaimable = userProfits.totalRewards / 10 ** Number(await tokenContract.methods.decimals().call());
        }else{
            refClaimable = 0;
            refClaimed = 0;
        }

        let obj = {
            contract, tokenContract,
            totalInvested: 0,
            userInvestment,
            refClaimed,
            refClaimable,
            userRewards
        }
        dispatch(addContractInfomation(obj));
    } catch (e) {
        return console.error(e.message);
    }
};

export const removeContractInfos = () => async (dispatch) => {
    try {
        return dispatch(deleteContractInformation());
    } catch (e) {
        return console.error(e.message);
    }
};
