import { createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";
import { CONTRACT_ABI, CONTRACT_ADDRESS, supportedRPCurl, TOKEN_CONTRACT_ABI, TOKEN_CONTRACT_ADDRESS } from "../Config/Config";
// Slice
const initialState = {
    contract: null,
    tokenContract: null,
    totalInvested: 0,
    totalUsers: 0,
    minimumInvest:0,
    adminFees:0,
    refferalRewards:0,
    publicWeb3:null,
};
const slice = createSlice({
    name: "PublicInformation",
    initialState,
    reducers: {
        addPublicInformation: (state, action) => {
            state.contract = action.payload.contract;
            state.tokenContract = action.payload.tokenContract;
            state.totalInvested = action.payload.totalInvested;
            state.minimumInvest = action.payload.minimumInvest;
            state.adminFees = action.payload.adminFees;
            state.refferalRewards = action.payload.refferalRewards;    
            state.publicWeb3 = action.payload.publicWeb3;            
            
        },
        deletePublicInformation: (state, action) => {
            state.contract = null;
            state.tokenContract = null;
            state.totalInvested = 0;
            state.minimumInvest = 0;
            state.adminFees = 0;
            state.refferalRewards = 0;             
            state.publicWeb3 = null; 
        },
    },
});

export default slice.reducer;

// Actions
const { addPublicInformation, deletePublicInformation } = slice.actions;

export const publicInfos = () => async (dispatch) => {
    try {
        let web3 = new Web3(supportedRPCurl);
        let contract = new web3.eth.Contract(
            CONTRACT_ABI,
            CONTRACT_ADDRESS
        );
        let tokenContract = new web3.eth.Contract(
            TOKEN_CONTRACT_ABI,
            TOKEN_CONTRACT_ADDRESS
        );

        // let minimumInvest = await contract.methods.minimumInvestment().call() / 10 ** Number(await tokenContract.methods.decimals().call());
        let adminFees = await contract.methods.adminFee().call() / 100;
        let refferalRewards = await contract.methods.refferalFee().call() / 100;
        console.log(adminFees);
        console.log(refferalRewards);
        let obj = {
            contract, tokenContract,refferalRewards, adminFees ,publicWeb3:web3
        }
        dispatch(addPublicInformation(obj));
    } catch (e) {
        return console.error(e.message);
    }
};

export const removePublicInfos = () => async (dispatch) => {
    try {
        return dispatch(deletePublicInformation());
    } catch (e) {
        return console.error(e.message);
    }
};
