import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import userInformation from "./UserSlice";
import contractSlice from "./ContractSlice";
import publicSlice from "./PublicSlice";

const reducer = combineReducers({
    userInformation,
    contractSlice,
    publicSlice,
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['userInformation/addUserInfomation','ContractInformation/addContractInfomation',,'PublicInformation/addPublicInformation'],
      // Ignore these field paths in all actions
    //   ignoredActionPaths: ['meta.arg', 'accountInfo.provider'],
      // Ignore these paths in the state
      ignoredPaths: ['userInformation.provider','userInformation.connector','contractSlice.stakeContract','contractSlice.contract','contractSlice.tokenContract','contractSlice.pairContract','contractSlice.factoryContract','contractSlice.routerContract','contractSlice.web3','publicSlice.stakeContract','publicSlice.contract','publicSlice.tokenContract','publicSlice.publicWeb3','publicSlice.factoryContract','publicSlice.routerContract','publicSlice.web3'],
    },
  }), 
})

export default store;