import { useWeb3React } from '@web3-react/core';
import { useState } from 'react';
import Web3 from 'web3';
import { useDispatch } from "react-redux";
import { accountInfos } from '../Redux/UserSlice';
import { useHandleError } from './useHandleError';
import { contractsInfos } from '../Redux/ContractSlice';
export function useWeb3Init(){    
    const {account} = useWeb3React();
    const [web3,setWeb3] = useState(null);
    const dispatch = useDispatch();
    const {handleError} = useHandleError();
    const initialize = async (provider) => {
        try{            
            console.log("connect");
            const web3 = new Web3(provider);            
            setWeb3(web3);
            dispatch(accountInfos(web3,account));
            dispatch(contractsInfos(web3,account));
        }catch(err){
            handleError(err);
        }
    }
    return {initialize,web3}
}