import { createSlice } from '@reduxjs/toolkit'
import { TokenAbi, TokenContractAddress, TOKEN_CONTRACT_ABI, TOKEN_CONTRACT_ADDRESS } from '../Config/Config';

// Slice
const initialState = {
    nativeBalance:0,
    tokenBalance:0,    
}
const slice = createSlice({
    name: 'userInformation',
    initialState,
    reducers: {
        addUserInfomation: (state, action) => {                      
            state.nativeBalance=action.payload.nativeBalance;
            state.tokenBalance=action.payload.tokenBalance;
        },
        deleteUserInformation: (state, action) => {            
            state.nativeBalance=0;
            state.tokenBalance=0;
        },
    },
});

export default slice.reducer

// Actions

const { addUserInfomation, deleteUserInformation } = slice.actions

export const accountInfos = (web3,account) => async dispatch => {

    try {          
        // console.log(web3,account);
        let nativeBalance = await web3.eth.getBalance(account);
        let tokenContract = await new web3.eth.Contract(TOKEN_CONTRACT_ABI,TOKEN_CONTRACT_ADDRESS);
        let decimals = await tokenContract.methods.decimals().call();
        let tokenBalance = Number(await tokenContract.methods.balanceOf(account).call())/10**Number(decimals);
        nativeBalance = Number(nativeBalance/1e18).toFixed(4);
        let obj = {nativeBalance,tokenBalance};
        dispatch(addUserInfomation(obj));
    } catch (e) {
        return console.error(e.message);
    }
}

export const removeInfo = () => async dispatch => {
    try {        
        return dispatch(deleteUserInformation())
    } catch (e) {
        return console.error(e.message);
    }
}