import { useWeb3React } from '@web3-react/core';
import { useState } from 'react';
import { useHandleError } from './useHandleError';

export function useConnection(){
    const {chainId, account, activate} = useWeb3React(); 
    const {handleError} = useHandleError();
    const [open, setOpen] = useState(false);
    const [connected, setConncted] = useState(false);
    
    const connect = async (provider,walletType) => {
        try{
            await activate(provider,(err)=>{
                console.log(err);
                handleError(err);
            });
            localStorage.setItem('wallet',walletType);
            localStorage.setItem('account',account);
            localStorage.setItem('chainId',chainId);
            setOpen(false);
            setConncted(true);
        }catch(err){
            setConncted(false);
            handleError(err);
        }
    }  
    return {connect,setOpen,open,connected,setConncted};
}