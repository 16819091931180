import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { supportedChainid, supportedExplorer, supportedNetworkName, supportedRPCurl, supportedSymbol } from "../Config/Config";
import { useConnected } from "./useConnected";

export function useSwitchChain(){
    const {active,chainId,library} = useWeb3React();
    const [switchMessage,setMessage] = useState("");
    const {handleConnected} = useConnected();
    const switchChain = async () => {
        if(active){
            const params = [{
                chainId: '0x' + supportedChainid.toString(16),
    
            }]
            const paraadd = [{
                chainId: '0x' + supportedChainid.toString(16),
                chainName: supportedNetworkName,
                nativeCurrency: {
                    name: supportedNetworkName,
                    symbol: supportedSymbol,
                    decimals: 18,
                },
                rpcUrls: [supportedRPCurl],
                blockExplorerUrls: [supportedExplorer],
            }]
            if(chainId != supportedChainid){
                await library.provider.request({ method: 'wallet_switchEthereumChain', params }).then(async (res)=>{
                    handleConnected();
                    console.log(res, " switch then");
                }).catch( async (switcherror) =>{
                    console.log(switcherror);
                    if (switcherror.code == 4902) {
                        // console.log("Error");
                        try {
                            await library.provider.request({ method: 'wallet_addEthereumChain', params:[
                            { chainId:paraadd[0].chainId,
                             chainName:paraadd[0].chainName,
                             rpcUrls:paraadd[0].rpcUrls,                        
                           }     
                           ] }).then(async () => {
                            handleConnected();
                            console.log("switched");
                        }).catch((e) => console.log(e))    
                        } catch (addError) {
                            // handle "add" error
                        }
                        handleConnected();
                    } else {
                        setMessage("Please Add Network to Switch");
                    }
                })
            }
        }   
    }

    return {switchChain,switchMessage};
}