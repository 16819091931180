import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import store from "./Redux/store";
import i18next from './i18n'

function getLibrary(provider) {
  const library = new Web3Provider(provider,'any');
  library.pollingInterval = 12000;
  return library;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <React.StrictMode>
          <App />
      </React.StrictMode>
    </Provider>
  </Web3ReactProvider>
);
