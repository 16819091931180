import { useWeb3React } from '@web3-react/core';
import { useState } from 'react';
export function useHandleError(){
    const [message,setMessage] = useState('');
    const handleError = async (error) => {
        console.log(error);
        if(error.code == 4001){
            var errMess;
            if (error.message) {   
                if(error.message.includes("execution reverted: ")){
                    error = error.message.replace("execution reverted: ", "");
                }else if(errMess.message.includes("execution reverted")){
                    error = error.message.replace("execution reverted", "");
                }else if(errMess.message.includes('MetaMask Tx Signature: ')){
                    error = error.message.replace("MetaMask Tx Signature: ", "");
                }
            }    
            setMessage(error);        
        }
        // setMessage(error);
    }
    return {handleError,message};
}