import { useWeb3React } from '@web3-react/core';
import { useHandleError } from './useHandleError';
import { useDispatch } from "react-redux";
import { removeInfo } from '../Redux/UserSlice';
import { removeContractInfos } from '../Redux/ContractSlice';
import { useConnection } from './useConnection';
export function useDisconnect(){
    const { deactivate} = useWeb3React();    
    const {handleError} = useHandleError();
    const dispatch = useDispatch();
    const {setConncted} = useConnection();
    const disconnect = async () => {
        try{     
            setConncted(false);
            deactivate();
            localStorage.removeItem('wallet');
            localStorage.removeItem('account');
            localStorage.removeItem('chainId');
            dispatch(removeInfo());
            dispatch(removeContractInfos());
        }catch(err){
            handleError(err);
        }
    }
    return {disconnect};
}