import React from 'react'

const Loading = () => {
  return (
    <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%',zIndex:'2000'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100vh',background: '#1a080800',backdropFilter: 'blur(10px)'}}>
            <p style={{fontSize:'20px'}}>Transaction in process, Please do not referesh the page...</p>
            <img src='assets/images/trade.svg' className='loadingImage' alt='loader'   width="200" height="200" />
        </div>
    </div>
  )
}

export default Loading