import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Index from "./Pages/Index";
import Connected from "./Pages/Connected";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { useWeb3Init } from "./Hooks/useWeb3init";
import { injected, walletconnect } from "./Config/Connectors";
import { useConnection } from "./Hooks/useConnection";
import { useConnected } from "./Hooks/useConnected";
function App() {
  const {active ,library,account} = useWeb3React();
  const {connected} =useConnection();
  const {initialize} = useWeb3Init();
  const {handleConnected} = useConnected();


  useEffect(()=>{
      // dispatch(publicInfos());        
      handleConnected();
  },[]);  

  useEffect(()=>{
      if(connected){
          if(library.provider) return;
          initialize(library.provider);
      }
  },[connected]);

  useEffect(()=>{
      if(library != undefined){
          if(library.provider == undefined) return;
          initialize(library.provider);
      }
  },[library]);

  useEffect(()=>{
      if(active){
          initialize(library.provider);
      }
  },[account]);  
  return (
    <div className="App">
      <Router >
        <Routes>
          {active ?
            <Route path="/" element={<Connected />} />
            :
            <Route path="/" element={<Index />} />
          }         
        </Routes>
      </Router>
    </div>
  );
}

export default App;
