import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import {FortmaticConnector} from "@web3-react/fortmatic-connector";
import { supportedChainid, supportedRPCurl } from "./Config";

export const injected = new InjectedConnector();
export const walletconnect = new WalletConnectConnector({
    rpc:{56 : supportedRPCurl},   
    // rpc: { 97: "https://data-seed-prebsc-1-s1.binance.org:8545"},
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: 2000,
    qrcodeModalOptions: {
      mobileLinks: ["metamask", "trust"],
    },
    chainId:56,
    supportedChainIds:[56,97],
});

// export const formatic = new FortmaticConnector({
//   apiKey: "pk_test_391E26A3B43A3350" ,
//   chainId:4
// })
  