import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useDisconnect } from "../Hooks/useDisconnect";
import { useWeb3Init } from "../Hooks/useWeb3init";
import { useDispatch, useSelector } from "react-redux";
import { contractsInfos } from "../Redux/ContractSlice";
import { API_URL, CONTRACT_ADDRESS, TOKEN_SYMBOL, WEBSITE_URL } from "../Config/Config";
import Loading from "./Loading";
import { ToastContainer, toast, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useSwitchChain } from "../Hooks/useSwitchChain";
import { publicInfos } from "../Redux/PublicSlice";
import { useConnected } from "../Hooks/useConnected";

const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut"
});

const Connected = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [rows, setRows] = useState([]);
  const [rewards,setRewards] = useState(0);
  const [refRewards,setRefRewards] = useState(0);
  const [isDisabled,setIsDisabled] = useState(false);
  const [isWithdrawDisabled,setIsWithdrawDisabled] = useState(true);
  const [message,setMessage] = useState('');
  const [value,setValue] = useState("");
  const { disconnect } = useDisconnect();
  const { initialize } = useWeb3Init();
  const { switchChain } = useSwitchChain();
  const { handleConnected } = useConnected();
  const { account, active, library, chainId } = useWeb3React();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const address = params.get("address");

  const userInformation = useSelector(state => state.userInformation);
  const ContractInformation = useSelector(state => state.contractSlice);
  const publicSlice = useSelector(state => state.publicSlice);

  // DEPOSIT
  const deposit = async () => {
    const { contract, tokenContract } = ContractInformation;
    const {publicWeb3} = publicSlice;
    console.log(publicWeb3);
    if(Number(value)>0 && value != ""){
        let payAmount = await publicWeb3.utils.toWei(value.toString(),'ether');
        if (Number(value) <= Number(userInformation.tokenBalance)) {

          try {
            let refferal = "0x0000000000000000000000000000000000000000";
            if (address) {
              refferal = address;
            }
            setLoad(true);
            let approve = await tokenContract.methods.approve(CONTRACT_ADDRESS, "10000000000000000000000").send({ from: account });
            let submit = await contract.methods.invest(payAmount, refferal).send({ from: account });
            setLoad(false);
            toast.success("Token Stake Successfully", { theme: 'dark' });
            initialize(library.provider);
            insertDeposit(value);
            depositeDetails();
          } catch (err) {
            setLoad(false);
            toast.warning(err.message, { theme: 'dark' });
            console.log(err);
          }
        } else {
          toast.error("Insufficient Balance", { theme: "dark" });
        }
    }else{
      toast.error("Please enter any amount", { theme: "dark" });
    }

  }

  // INSERT INVESTMENT DATA
  const insertDeposit = async (amount) => {
    var formdata = new FormData();
    formdata.append("address", account);
    formdata.append("amount", amount);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}addStaking.php`, requestOptions)
      .then(response => response.json())
      .then(result => {})
      .catch(error => console.log('error', error));
  }

  // WITHDRAW DEPOSIT
  const withdraw = async () => {
    if (active) {
      const { contract } = ContractInformation;

      try {
        setLoad(true);
        let submit = await contract.methods.withdrawInvestment().send({ from: account });
        setLoad(false);
        toast.success("Token unstake successfully", { theme: "dark" });
        initialize(library.provider);
        updateWithrawStatus();
        depositeDetails();
      } catch (err) {
        toast.warning(err.message, { theme: 'dark' });
        setLoad(false);
      }
    } else {
      toast.error("Please connect your wallet", { theme: "dark" });
    }
  }

  const updateWithrawStatus = async () => {
    var formdata = new FormData();
    formdata.append("address", account);
    formdata.append("withdrawStatus", 1);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}updateWithdraw.php`, requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  // CLAIM REFFEREAL REWARDS
  const claimReward = async () => {
    if (active) {
      const { contract } = ContractInformation;
      if(rewards>0){
          try {
            setLoad(true);
            let submit = await contract.methods.claimRewards().send({ from: account });
            setLoad(false);
            toast.success("Reward claim successfully", { theme: "dark" });
            initialize(library.provider);
          } catch (err) {
            toast.warning(err.message, { theme: 'dark' });
            setLoad(false);
          }
      }else{
        toast.warning("You dont have any rewards!", { theme: 'dark' });
      }
    } else {
      toast.error("Please connect your wallet", { theme: "dark" });
    }
  }

  const timeSince = (date) => {
    var seconds = Math.floor((new Date().getTime() - date * 1000) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  const copyLink = (e) => {
    e.preventDefault();
    if (active) {
      e.target.ref.select();
      document.execCommand('copy');
      e.target.focus();
      toast.success("Link Copied", { theme: 'dark', autoClose: 600 })
    } else {
      toast.warning("Please connect wallet", { theme: "dark" });
    }
  }

  const addToken = (e) => {

  }

  const depositeDetails = async () => {
    if (active && ContractInformation.contract != null) {
      setLoadData(false);
      setRows([]);
      const { contract, tokenContract } = ContractInformation;
      console.log(contract);
      let totalDeposits = await contract.methods.getUserTotalNoDeposit(account).call();
      console.log(totalDeposits);
      let i = 0;
      let j = 1;
      while (i < totalDeposits) {
        let depositInformation = await contract.methods.getUserDepositDetail(i, account).call();
        let amount = Number(depositInformation.amount) / 10 ** Number(await tokenContract.methods.decimals().call());
        // console.log(depositInformation, " fetched");
        if (depositInformation.isWithdraw == false) {
          setIsWithdrawDisabled(false);
          let obj = { investmentAmount: amount, depositTime: depositInformation.checkpoint, sno: j++ };
          setRows(prev => [...prev, obj]);
        }
        i++;
      }
    }
  }

  const handleAmountChange = (e) => {
    let value = e.target.value;
    setValue(value);
    if(Number(value)<0 || value == ""){
      setIsDisabled(true);
      setMessage('Enter valid amount');
    }else{
      if(active){
        if (Number(value) > Number(userInformation.tokenBalance)) {
          setMessage('Insufficient Balance');
          setIsDisabled(true);
        }else{
          setMessage('');
          setIsDisabled(false);
        }
      }else{
        setMessage('');
        setIsDisabled(false);
      }
    }
  }

  // useEffect(() => {
  //   if (plansLoaded.current) {
  //       // setPlansLoaded(false);
  //       plansLoaded.current = false;
  //       depositeDetails();
  //   }
  // }, [loadData,account,ContractInformation.contract]);


  useEffect(() => {
    dispatch(publicInfos());
    handleConnected();
  }, []);

  useEffect(() => {
    if (active && loadData) {
      depositeDetails();
    }
  }, [loadData, account, ContractInformation.contract]);

  useEffect(() => {
    depositeDetails();
  }, [account]);

  useEffect(() => {
    switchChain();
  }, [chainId]);

  const settingRewards = async () => {
    const { contract, tokenContract } = ContractInformation;
    let userRewards = await contract.methods.calclulateReward(account).call() / 10 ** Number(await tokenContract.methods.decimals().call());

    if(userRewards>0){
        let userProfits  = await contract.methods.refferralRewards(account).call();
        // let refClaimed = userProfits.totalEarn / 10 ** Number(await tokenContract.methods.decimals().call());
        let refClaimable = userProfits.totalRewards / 10 ** Number(await tokenContract.methods.decimals().call());
        setRefRewards(refClaimable);
    }
    setRewards(userRewards);
  }

  useEffect(()=>{
    if(active){


      if (!ContractInformation.userRewards) return;
      timerRef.current = setInterval(async () => {
        // console.log('coll');
        await settingRewards();
      }, 1000);
      return () => clearInterval(timerRef.current);

    }
  },[ContractInformation.contract,account]);

  return (
    <div>
      {load ?
        <Loading />
        :
        <div>
          <ToastContainer />
          <div className="wrapper">

            <div className="header">
              <div className="logo">
                <a href=""><img src="assets/images/logo.svg"/><h1>Jorjinvest</h1></a>
              </div>
              <div className="button">
                <button
                    onClick={() => {
                      localStorage.removeItem("accountsMeta");
                      navigate("/");
                      window.location.reload();
                    }}
                ><i className="fas fa-sign-out-alt" onClick={() => disconnect()}></i>
                </button>
              </div>
              <div className="clear display-mobile"></div>
              <div className="address">
                <b><i className="fas fa-wallet"></i> Wallet</b>
                <p>{account}</p>
              </div>
            </div>

            <div style={{'marginTop':'2rem'}}>
              <h4>{TOKEN_SYMBOL} Token</h4>
            </div>
            <div className="box">
              <div className="profit">
                {/*<div className="total">
                  <i className="fas fa-dollar-sign"></i>
                  <span>Total Profit</span>
                  <strong>{Number(ContractInformation.refClaimed).toFixed(6)} {TOKEN_SYMBOL}</strong>
                </div>*/}
                <div className="claim">
                  <span>Total Rewards</span>
                  <strong>{Number(rewards).toFixed(6)} {TOKEN_SYMBOL}</strong>
                </div>
                <div className="claim">
                  <span>Referral profits</span>
                  <strong>{Number(refRewards).toFixed(6)} {TOKEN_SYMBOL}</strong>
                </div>
                <button onClick={() => claimReward()} style={{ background: "#19ef98", color: '#000', padding: '1rem 2rem', borderRadius: '20px', border: 'none' }}>Claim Profits Now</button>
              </div>

              <div className="wallet">
                <div className="balance">
                  <i className="fas fa-wallet"></i>
                  <span>Your liquidity:</span>
                  <strong>{ContractInformation.userInvestment} {TOKEN_SYMBOL}</strong>
                </div>
                <div className="daily">
                  <p>Your Liquidity profit is {Number(rewards).toFixed(6)} {TOKEN_SYMBOL}</p>
                </div>
                <p>
                  You will receive {Number((rewards * (100 - (Number(publicSlice.adminFees) + Number(publicSlice.refferalRewards)))) / 100).toFixed(6)} daily profit because of {(100 - (Number(publicSlice.adminFees) + Number(publicSlice.refferalRewards)))}% profit shares.
                </p>
                <div className="fill">
                  <input type="number" min={0} id="fname" name="amount" value={value} placeholder="Enter Amount" onChange={handleAmountChange}/>
                </div>
                <div>
                  <span className="text-white">{message}</span>
                </div>
                <div>
                  <button href="" className="deposit" onClick={() => deposit()} disabled={isDisabled}>
                    Deposit
                  </button>
                  <button className="withdrawal" onClick={() => withdraw()} disabled={isWithdrawDisabled}>Withdrawal All Amount</button>
                </div>
              </div>
            </div>

            <div className="transactions">
              <h2>Transactions</h2>

              <table>
                <tr>
                  <th>S. No.</th>
                  <th>Investment Amount</th>
                  <th>Deposit Time</th>
                </tr>
                {rows.length > 0 ?

                  rows.map((e, idx) => (<tr key={idx}>
                    <td>{e.sno}</td>
                    <td>{e.investmentAmount}</td>
                    <td>{timeSince(Number(e.depositTime))} Ago</td>
                  </tr>))
                  :
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>No Data Found!</td>
                  </tr>
                }
              </table>
            </div>

            <div className="footer">
              <div className="logo">
                <img src="assets/images/trade.svg" />
                <div className="text">
                  <h3>Jorji Invest</h3>
                  <span>
                    © 2023 JorjInvest.net LLC.<a href="https://github.com/Jorjinvest">Open Source</a>
                  </span>
                </div>
              </div>
              <div className="clear display-mobile"></div>
              <form onSubmit={copyLink}>
                <div className="ref" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <span>Referral link</span>

                  <input name="ref" className="form__control" value={active ? WEBSITE_URL + "?address=" + account : ""} placeholder="" />
                  <button className="coptbtn" type="submit">
                    <i
                      className="fas fa-copy copy"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
          </div>

          <img className="bg" src="assets/images/footer.svg" />

          <script src="assets/main.js"></script>
        </div>}
    </div>
  );
};

export default Connected;
