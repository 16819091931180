import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { injected, walletconnect } from "../Config/Connectors";
import { useConnection } from "../Hooks/useConnection";
import { useDisconnect } from "../Hooks/useDisconnect";

// wallet image import

import metaimg from '../../public/assets/images/MetaMask_Fox.svg.png'
import walletsconnect from '../../public/assets/images/walletconnect-logo.com.png'
import {TOKEN_SYMBOL} from "../Config/Config";



const Index = () => {
  const { account, active } = useWeb3React();
  const { connect } = useConnection();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  useEffect(() => {
    if (active) {
      navigate("/connected");
    }
  }, [active]);

  //   const [reload, setReload] = useState(false);
  //   useEffect(() => {
  //     if (localStorage.getItem("accountsMeta")) {
  //       setReload(true);
  //     } else {
  //       setReload(false);
  //     }
  //   }, []);
  //   let navigate = useNavigate();
  // Metamask
  // const connectWallet = async () => {
  //   if (window.ethereum) {
  //     //check if Metamask is installed
  //     try {
  //       const address = await window.ethereum.enable(); //connect Metamask
  //       const obj = {
  //         connectedStatus: true,
  //         status: "",
  //         address: address,
  //       };
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });
  //       const account = accounts[0];
  //       localStorage.setItem("accountsMeta", account);

  //       navigate("/connected");
  //       return obj;
  //     } catch (error) {
  //       return {
  //         connectedStatus: false,
  //         status: "🦊 Connect to Metamask using the button on the top right.",
  //       };
  //     }
  //   } else {
  //     return {
  //       connectedStatus: false,
  //       status:
  //         "🦊 You must install Metamask into your browser: https://metamask.io/download.html",
  //     };
  //   }
  // };

  return (
    <div>
      <div className="wrapper">

        <div className="header">
          <div className="logo">
            <a href=""><img src="assets/images/logo.svg"/></a>
          </div>
          <div className="button">
            <a href="https://jorjinvest.net"><i className="fas fa-home"></i></a>
          </div>
          <div className="clear display-mobile"></div>
          <div className="address">
            <span>Status</span>
            <strong>Not Connected</strong>
          </div>
        </div>

        <div className="main">
          <div className="connected">
            <div className="box">
              <h2>You are not connected.</h2>
              <p>You should connect your wallet to getting start using.</p>
              {/* <div onClick={() => connectWallet()}>
                <img src="assets/images/metamask.png" />
                <strong>Metamask</strong>
                <i className="fas fa-long-arrow-right"></i>
              </div>
              <Link to="connected">
                <img src="assets/images/walletconnect.png" />
                <strong>WalletConnect</strong>
                <i className="fas fa-long-arrow-right"></i>
              </Link> */}
              <div class="col-md-12">
                <div className="mx-auto">
                  <img style={{ float: 'none', width: '128px', height: '128px'}} src={'assets/images/' + TOKEN_SYMBOL + '.svg'}/>
                </div>
                <p className="h1">Connect Wallet</p>

                <p>
                  No wallet connected. Please connect wallet to use our dapp.
                </p>

                <div id="prepare">
                  <div className="" style={{ gap: '10px', display: 'block', alignItems: 'center', justifyContent: 'center' }}>

                      <button class="btn btn-primary" style={{width:'40%', marginRight:'10px', background:'#7919ff', borderColor:'#7919ff'}} onClick={() => connect(injected, "metamask")}>
                        <span>
                          <img src={metaimg} alt='metamask images ' />
                        </span>
                        <span>
                        <h5>Metamask</h5>
                        </span>
                      </button>

                      <button class="btn btn-primary" style={{width:'40%', background:'#7919ff', borderColor:'#7919ff'}} onClick={() => connect(walletconnect, "walletConnect")}>
                        <span>
                          <img src={walletsconnect} alt='metamask images ' />
                        </span>
                        <span>
                          <h5>Wallet Connect</h5>
                        </span>

                      </button>

                  </div>
                  {/*<button
                    class="btn btn-primary"
                    id="btn-connect"
                    onClick={() => {
                      setTimeout(() => {
                        if (localStorage.getItem("accountsMeta")) {
                          window.location.reload();
                        }
                      }, 1000);
                    }}
                  >
                    Connect wallet
                  </button>*/}
                </div>

                <div id="connected" style={{ display: "none" }}>
                  <button class="btn btn-primary" onClick={() => disconnect()}>
                    Disconnect wallet
                  </button>

                  <hr />

                  <div id="network">
                    <p>
                      <strong>Connected blockchain:</strong>{" "}
                      <span id="network-name"></span>
                    </p>

                    <p>
                      <strong>Selected account:</strong>{" "}
                      <span id="selected-account"></span>
                    </p>
                  </div>

                  <hr />

                  <h3>All account balances</h3>

                  <table class="table table-listing">
                    <thead>
                      <th>Address</th>
                      <th>BNB balance</th>
                    </thead>

                    <tbody id="accounts"></tbody>
                  </table>

                  <p>
                    Please try to switch between different accounts in your
                    wallet if your wallet supports this functonality.
                  </p>
                </div>
              </div>
            </div>
            <div className="clear"></div>
            <div className="terms">
              <p>
                By connecting your wallet, you acknowledge that you have read
                and agree with
                <a href="https://jorjinvest.net/terms">
                  <strong style={{ padding: "0 5px" }}>
                    terms of services
                  </strong>
                </a>
                and you are above than 18 years old.
              </p>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="logo">
            <img src="assets/images/trade.svg" />
            <div className="text">
              <h3>Jorjinvest.net</h3>
              <span>
                © 2022 Jorjinvest.net LLC. <a href="https://github.com/Jorjinvest">Open Source</a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <img className="bg" src="assets/images/footer.svg" />

      <script src="assets/main.js"></script>
    </div>
  );
};

export default Index;
