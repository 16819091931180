
import { injected, walletconnect } from '../Config/Connectors';
import { useConnection } from './useConnection';
import { useHandleError } from './useHandleError';

export function useConnected(){    
    const {handleError} = useHandleError();
    const {connect} = useConnection();
    const handleConnected = async () => {
        try{
            let wallet = localStorage.wallet;
            console.log(wallet);
            if(wallet != "" && wallet != undefined){
                if(wallet == "metamask"){
                    connect(injected,wallet);
                }else if(wallet == "walletConnect"){
                    connect(walletconnect,wallet);
                }
            }
        }catch(err){
            handleError(err);
        }
    }
    return {handleConnected};
}